import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { sendCotizacion } from "../../service/cotizar";
import { useSpinner } from "../../../../context/spinnerContext";
import SpinnerComponent from "../../../../components/SpinnerComponent";
import { fetchAreaCodes } from "../../../../actions/areaCodes";
import ModalInterior from "../Modals/ModalInterior";

export const ViewStepOneComponent = ({ selectedOptions, isGnc, kms }) => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const { phone } = useSelector((state) => state.userReducer);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();
  const [codArea, setcodArea] = useState(8);
  const [loadingPost, setLoadingPost] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showLoading, hideLoading, showSpinner } = useSpinner();
  const [areaCodes, setAreaCodes] = useState([]);
  const [areaCodesOpen, setAreaCodesOpen] = useState(false);
  const [showModalInterior, setShowModalInterior] = useState(false);
  const [nameCity, setNameCity] = useState();

  // Watch the values of name and telefono
  const name = watch("name");
  const telefono = watch("telefono");

  // Enable the button when name and telefono are both filled
  useEffect(() => {
    setIsButtonEnabled(!!name && !!telefono);
  }, [name, telefono]);

  useEffect(() => {
    if (phone === null) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [phone]);

  const sendSale = (data) => {
    setLoadingPost(true);
    const telefonoCompleto = `549${data.cod_area}${data.telefono}`;
    sendCotizacion(
      selectedOptions?.brand?.name,
      selectedOptions?.brand?.id,
      selectedOptions?.model?.name,
      selectedOptions?.model?.id,
      selectedOptions?.version?.name,
      selectedOptions?.version?.id,
      selectedOptions?.year?.name,
      isGnc,
      kms,
      telefonoCompleto,
      data.cod_area,
      nameCity,
      data.name,
      data.email,
      navigate,
      dispatch,
      setLoadingPost
    );
    reset();
  };

  const handleChangeAreaCode = async (e) => {
    if (e.target.value === "") {
      setAreaCodesOpen(false);
    } else {
      setAreaCodesOpen(true);
    }
    setcodArea(e.target.value.length);
    const a = await fetchAreaCodes(e.target.value);
    setAreaCodes(a);
  };

  const handleClickAreaCode = (code, city) => {
    setAreaCodesOpen(false);
    setValue("cod_area", code);
    setNameCity(city);
    if (city !== "AMBA" && city !== "Bs. As." && setShowModalInterior) {
      setShowModalInterior(true);
    }
  };

  const handleEventLeft = () => {
    window.location.reload();
  };

  const handleEventRight = () => {
    setShowModalInterior(false);
  };

  return (
    <>
      <div
        className={
          isPageWide ? "card-contact-data" : "mobile-dropdown-container"
        }
      >
        <div className="card-contact-header">
          <p className="contact-header-title">¡Completá tus datos!</p>
        </div>
        {showSpinner ? (
          <div style={{ marginTop: "50px" }}>
            <SpinnerComponent />
          </div>
        ) : (
          <>
            <div style={{ padding: "12px", height: "364px" }}>
              <form
                onSubmit={handleSubmit(sendSale)}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="label-input">Nombre completo *</label>
                    <input
                      placeholder="Ingrese su nombre completo"
                      className="base-input"
                      {...register("name", {
                        required: true,
                      })}
                    />
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="label-input">Email</label>
                    <input
                      placeholder="Ingrese su email"
                      type="email"
                      className="base-input"
                      {...register("email")}
                    />
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="input-labels">Teléfono celular</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="phone-number">+549</p>
                      <input
                        type="text"
                        className="base-input"
                        placeholder="Ej: 11"
                        maxLength={4}
                        style={{ width: "65px", marginRight: "10px" }}
                        {...register("cod_area", {
                          required: true,
                        })}
                        /* onChange={(e) => {
                          setcodArea(e.target.value.length);
                        }} */
                        onChange={handleChangeAreaCode}
                        autoComplete="off"
                      />
                      {areaCodesOpen && (
                        <div className="base-input area-codes-container">
                          {areaCodes?.map((code) => {
                            for (let key in code) {
                              return (
                                <div
                                  key={code[key]}
                                  className="area-codes-option"
                                  onClick={() =>
                                    handleClickAreaCode(code[key], key)
                                  }
                                >
                                  <label className="area-codes-number">
                                    {code[key]}
                                  </label>
                                  <label className="area-codes-city">
                                    {key}
                                  </label>
                                </div>
                              );
                            }
                          })}
                        </div>
                      )}
                      <input
                        type="text"
                        className="base-input ms-1"
                        placeholder="Sin 15"
                        {...register("telefono", {
                          required: true,
                          maxLength: Math.abs(codArea - 10),
                          minLength: Math.abs(codArea - 10),
                        })}
                        maxLength={Math.abs(parseInt(codArea?.length) - 10)}
                        minLength={Math.abs(parseInt(codArea?.length) - 10)}
                      />
                    </div>
                    <div className="mt-1">
                      {errors.name && errors.name.type === "required" && (
                        <p className="text-error">El nombre es requerido.</p>
                      )}
                      {errors.cod_area &&
                        errors.cod_area.type === "required" && (
                          <p className="text-error">
                            El código de area es requerido.
                          </p>
                        )}
                      {errors.cod_area &&
                        errors.cod_area.type === "maxLength" && (
                          <p className="text-error">
                            El código de area debe contener máximo 4 dígitos.
                          </p>
                        )}
                      {errors.telefono &&
                        errors.telefono.type === "maxLength" && (
                          <p className="text-error">
                            El número de teléfono debe ser de{" "}
                            {Math.abs(codArea - 10)} dígitos y sin 15.
                          </p>
                        )}
                      {errors.telefono &&
                        errors.telefono.type === "minLength" && (
                          <p className="text-error">
                            El número de teléfono debe ser de{" "}
                            {Math.abs(codArea - 10)} dígitos y sin 15.
                          </p>
                        )}
                      {errors.telefono &&
                        errors.telefono.type === "required" && (
                          <p className="text-error">
                            El número de teléfono es requerido.
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-between align-items-center">
                  {loadingPost && (
                    <Spinner
                      animation="border"
                      style={{
                        margin: "10px",
                        color: " #7340d8",
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <BasePurpleButtonComponent
                    buttonText={loadingPost ? "Enviando..." : "Continuar"}
                    buttonDisabled={loadingPost || !isButtonEnabled}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </>
        )}
      </div>
      <ModalInterior
        setShow={setShowModalInterior}
        show={showModalInterior}
        title={"¿Vivís en el interior del país?"}
        subtitle={
          isPageWide
            ? "Por el momento, solo realizamos inspecciones en la\nCiudad Autónoma de Buenos Aires (CABA)."
            : "Por el momento, solo realizamos\ninspecciones en la Ciudad Autónoma\nde Buenos Aires (CABA)."
        }
        subtitleTwo={"Agradecemos tu comprensión"}
        textBtnLeft={"Entiendo :("}
        textBtnRight={"¡Avancemos! :)"}
        handleEventLeft={handleEventLeft}
        handleEventRight={handleEventRight}
      />
    </>
  );
};
